<template>
  <div class="product_jepaas_content">
    <div class="box_top">
      <Header
        ref="header"
        class="page_hea"
      />
      <div class="top_tlite">
        <p class="title">
          JEPaaS云平台
        </p>
        <p class="subtitle">
          四大核心功能承载。赋能企业信息化进程
        </p>
        <div
          class="btn"
          @click="openqq"
        >
          立即咨询
        </div>
      </div>
      <div class="top_img">
        <img
          v-lazy="jepaas_bg"
          alt
        >
      </div>
    </div>
    <div class="page_content">
      <div class="section spe">
        <p class="title">
          强大的企业数字化中台
        </p>
        <p
          class="subtitle short"
        >
          JEPaaS将原有分散的企业应用系统通过以通信服务、数据库操作、应用式配置中心、认证中心等在内的多种基础组件进行快速对接，基于多维内存数据库技术在数据架构层实现数据信息的汇总，形成标准化数据，通过统一数据实现所有相关业务系统的集中管理。
        </p>
        <img
          v-lazy="cpu"
          class="cpu_img"
          alt
        >
      </div>
      <div class="section swiper">
        <p class="title">
          低代码快速开发
        </p>
        <p
          class="subtitle"
        >
          JEPaaS是一款优秀的软件平台产品，可视化开发环境，低代码拖拽式配置开发，操作极其简单，可以帮助解决Java项目80%的重复工作，让开发更多关注业务逻辑，大大提高开发效率，能帮助公司大幅节省人力成本和时间成本，同时又不失灵活性。适用于搭建 OA、ERP、CRM、HR、HIS等所有的企业信息管理系统（且适用于集团公司部署）。
        </p>
        <div class="swiper_wrp">
          <div class="left">
            <div
              v-for="(item, index) in swiperlist"
              :key="index"
              :class="[{'active': swipercurrent.label === item.label}]"
              @click="handleSwiperChange(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="right">
            <el-carousel
              v-for="(list, i) in swiperlist"
              v-show="list.label === swipercurrent.label"
              :key="i"
              indicator-position="outside"
              :interval="2000"
            >
              <el-carousel-item
                v-for="(item, index) in list.children"
                :key="index"
              >
                <div class="swiper-items">
                  <p class="title">
                    {{ item.title }}
                  </p>
                  <p class="subtitle">
                    {{ item.subtitle }}
                  </p>
                  <div class="img_box">
                    <img
                      :ref="item.ref"
                      style="width: 100%;"
                      :src="item.img"
                      alt
                      :class="item.ref"
                    >
                  </div>
                  <p class="text">
                    {{ item.text }}
                  </p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="title">
          SaaS应用快速开发及管理
        </p>
        <div class="tablist">
          <div
            v-for="(item, index) in saaslist"
            :key="index"
            :class="[{'active':item.label === saascurrent.label}]"
            @click="handleSaasListTab(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <p class="subtitle">
          {{ saascurrent.subtitle }}
        </p>
        <img
          :src="saascurrent.img"
          alt
        >
      </div>
      <div class="section imgth">
        <p class="title">
          强大接口 实现万物互联
        </p>
        <p
          class="subtitle"
        >
          JEPaaS的大量的工业接口服务可供业务系统调用，例如：MODBUS、DCS、PLC、SCADA、OPC等。这些强大的数据接口可以从“数据感知层”中读取高价值数据并传输到“数据应用层”加以利用，最终形成万物互联。
        </p>
        <div class="doubleimg">
          <div
            v-for="(item, index) in doublelist"
            :key="index"
          >
            <img
              :src="item.img"
              alt
            >
            <p>{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import jepaas_bg from '@/assets/imgs/product/jepaas_bg.png';
import cpu from '@/assets/imgs/product/cpu.png';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import paas_01 from '@/assets/imgs/product/paas_01.png';
import paas_02 from '@/assets/imgs/product/paas_02.png';
import paas_03 from '@/assets/imgs/product/paas_03.png';
import imgth_l from '@/assets/imgs/product/imgth_l.png';
import imgth_r from '@/assets/imgs/product/imgth_r.png';

import core1_1 from '@/assets/imgs/product/jepaas/core1_1.png';
import core1_2 from '@/assets/imgs/product/jepaas/core1_2.png';
import core1_3 from '@/assets/imgs/product/jepaas/core1_3.png';
import core1_4 from '@/assets/imgs/product/jepaas/core1_4.png';
import core2_1 from '@/assets/imgs/product/jepaas/core2_1.png';
import core2_2 from '@/assets/imgs/product/jepaas/core2_2.png';
import core2_3 from '@/assets/imgs/product/jepaas/core2_3.png';
import core2_4 from '@/assets/imgs/product/jepaas/core2_4.png';
import core3_1 from '@/assets/imgs/product/jepaas/core3_1.png';
import core4_1 from '@/assets/imgs/product/jepaas/core4_1.png';
import core4_2 from '@/assets/imgs/product/jepaas/core4_2.png';
import core5_1 from '@/assets/imgs/product/jepaas/core5_1.png';
import core6_1 from '@/assets/imgs/product/jepaas/core6_1.png';
import core6_2 from '@/assets/imgs/product/jepaas/core6_2.png';
import core6_3 from '@/assets/imgs/product/jepaas/core6_3.png';
import core7_1 from '@/assets/imgs/product/jepaas/core7_1.png';
import core7_2 from '@/assets/imgs/product/jepaas/core7_2.png';
import core8_1 from '@/assets/imgs/product/jepaas/core8_1.png';
import core8_2 from '@/assets/imgs/product/jepaas/core8_2.png';
import core9_1 from '@/assets/imgs/product/jepaas/core9_1.png';
import core9_2 from '@/assets/imgs/product/jepaas/core9_2.png';
import core9_3 from '@/assets/imgs/product/jepaas/core9_3.png';
import core10_1 from '@/assets/imgs/product/jepaas/core10_1.png';
import core11_1 from '@/assets/imgs/product/jepaas/core11_1.png';
import core11_2 from '@/assets/imgs/product/jepaas/core11_2.png';
import core11_3 from '@/assets/imgs/product/jepaas/core11_3.png';
import core11_4 from '@/assets/imgs/product/jepaas/core11_4.png';
import core12_1 from '@/assets/imgs/product/jepaas/core12_1.png';

export default {
  name: 'Jepaas',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      jepaas_bg,
      cpu,
      saaslist: [
        {
          label: '开发架构',
          subtitle:
            'JEPaaS拥有的独立数据库有助于简化数据模型的扩展设计，满足不同租户的独特需求；另一方面，共享数据库，隔离数据架构，为安全性要求较高的租户提供了一定程度的逻辑数据隔离，每个数据库可以支持更多的租户数量。',
          img: paas_01,
        },
        {
          label: '快速搭建及上架',
          subtitle:
            'JEPaaS提供了丰富的平台资源可助您快速创建自己的SaaS应用，还能通过平台的商城进行上架授权，JEPaaS并提供运营监控机制。',
          img: paas_02,
        },
        {
          label: '运营管理',
          subtitle:
            '通过JEPaaS的SaaS运营管理体系，可以对产品进行灵活管理：自定义产品信息、价格、产品功能范围等；同时，商城提供按照时间、人员的购买方式，并支持在线支付。并且还可以及时了解您的SaaS产品运营状况，直观的数据展示能助您及时调整运营策略。',
          img: paas_03,
        },
      ],
      doublelist: [
        {
          img: imgth_l,
          label: '接口引擎设计图',
        },
        {
          img: imgth_r,
          label: 'JEPaaS对互联网的支撑',
        },
      ],
      saascurrent: {},
      swiperlist: [
        {
          label: '自定义功能',
          children: [
            {
              title: '1.表功能自定义',
              subtitle:
                'JEPaaS平台支持oracle，sqlserver、mysql等主流数据库，支持包括树形表、单表、主子表、视图在内的多种表类型，可以将表、字段、主外键、索引统一管理，利用工具快速建表以及实现对表的维护与更新。',
              img: core1_1,
              text: '数据结构引擎客户端操作界面',
              ref: 'core1_1',
            },
            {
              title: '1.表功能自定义',
              subtitle:
                'JEPaaS平台支持oracle，sqlserver、mysql等主流数据库，支持包括树形表、单表、主子表、视图在内的多种表类型，可以将表、字段、主外键、索引统一管理，利用工具快速建表以及实现对表的维护与更新。',
              img: core1_2,
              text: '数据结构变更日志操作界面',
              ref: 'core1_2',
            },
            {
              title: '1.表功能自定义',
              subtitle:
                'JEPaaS平台支持oracle，sqlserver、mysql等主流数据库，支持包括树形表、单表、主子表、视图在内的多种表类型，可以将表、字段、主外键、索引统一管理，利用工具快速建表以及实现对表的维护与更新。',
              img: core1_3,
              text: 'E-R展示',
              ref: 'core1_3',
            },
            {
              title: '2.表结构自定义',
              subtitle:
                'JEPaaS平台支持不同用户依据自身需求对门户进行自定义设置。门户引擎提供了包括功能、图表、报表、展板、新闻、自定义插件、HTML插件等在内的多种板块，用户可自行规划、灵活添加、随时调整，实现门户的个性化布局。',
              img: core1_4,
              text: '表单设计工具操作界面',
              ref: 'core1_4',
            },
          ],
        },
        {
          label: '工作流引擎',
          children: [
            {
              title: '1.便捷流程设计',
              subtitle:
                'JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。',
              img: core2_1,
              text: 'Web流程编辑器',
              ref: 'core2_1',
            },
            {
              title: '1.便捷流程设计',
              subtitle:
                'JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。',
              img: core2_2,
              text: '流程入口',
              ref: 'core2_2',
            },
            {
              title: '1.便捷流程设计',
              subtitle:
                'JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。',
              img: core2_3,
              text: '流程审批',
              ref: 'core2_3',
            },
            {
              title: '1.便捷流程设计',
              subtitle:
                'JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。',
              img: core2_4,
              text: '流程追踪',
              ref: 'core2_4',
            },
          ],
        },
        {
          label: '即时通讯(IM)',
          children: [
            {
              title: '1.即时通讯(IM)',
              subtitle:
                'JEPaaS提供稳定高效的即时通讯服务，支持多种类型的信息沟通与用户及社群管理，轻松建立实时的传播矩阵，保证了信息交流的顺畅与安全',
              img: core3_1,
              text: 'IM界面',
              ref: 'core3_1',
            },
          ],
        },
        {
          label: 'SaaS运营',
          children: [
            {
              title: '1.SaaS快速搭建及上架',
              subtitle:
                '您可使用JEPaaS快速开发自己的SaaS产品，JEPaaS提供了丰富的平台资源可助您快速创建自己的SaaS应用，然后您还能通过平台的商城进行上架授权，JEPaaS并提供运营监控机制。',
              img: core4_1,
              text: 'SaaS商城界面',
              ref: 'core4_1',
            },
            {
              title: '2.SaaS运营管理',
              subtitle:
                '通过JEPaaS的SaaS运营管理体系，您可以对产品进行灵活管理：自定义产品信息、价格、产品功能范围等；同时，商城提供按照时间、人员的购买方式，并支持在线支付。您可及时了解您的SaaS产品运营状况，直观的数据展示能助您及时调整运营策略。并可以将用户使用该系统的运行数据、用户运维，对指定用户进行定制化扩展。',
              img: core4_2,
              text: '运营监控界面',
              ref: 'core4_2',
            },
          ],
        },
        {
          label: '钉钉微信对接',
          children: [
            {
              title: '1.钉钉微信对接',
              subtitle:
                'JEPaaS集成钉钉与企业微信，通过组织架构的同步对接。可实现消息提醒、微信办公、钉钉办公，让您的工作更高效更轻松。',
              img: core5_1,
              text: '钉钉、微信无缝对接',
              ref: 'core5_1',
            },
          ],
        },
        {
          label: 'App快速开发',
          children: [
            {
              title: '1.App快速开发',
              subtitle:
                'JEPaaS提供在线的App开发工具，使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。',
              img: core6_1,
              text: 'APP功能设计器',
              ref: 'core6_1',
            },
            {
              title: '1.App快速开发',
              subtitle:
                'JEPaaS提供在线的App开发工具，使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。',
              img: core6_2,
              text: '列表 表单 IM',
              ref: 'core6_2',
            },
            {
              title: '1.App快速开发',
              subtitle:
                'JEPaaS提供在线的App开发工具，使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。',
              img: core6_3,
              text: '流程 图表 外勤',
              ref: 'core6_3',
            },
          ],
        },
        {
          label: 'App插件库',
          children: [
            {
              title: '1.App插件库',
              subtitle: '',
              img: core7_1,
              text: '功能强大全面的APP插件库',
              ref: 'core7_1',
            },
            {
              title: '1.App插件库',
              subtitle: '',
              img: core7_2,
              text: '插件应用实例',
              ref: 'core7_2',
            },
          ],
        },
        {
          label: '统一文件管理',
          children: [
            {
              title: '1.统一文件管理',
              subtitle:
                '文件库提供网盘功能，可实现文件快速上传管理。该文件库贯穿整个系统，在指定业务选文件可选文件，指定业务文件需要保留也可直接保存到网盘。',
              img: core8_1,
              text: '文件资源管理',
              ref: 'core8_1',
            },
            {
              title: '1.统一文件管理',
              subtitle:
                '文件库提供网盘功能，可实现文件快速上传管理。该文件库贯穿整个系统，在指定业务选文件可选文件，指定业务文件需要保留也可直接保存到网盘。',
              img: core8_2,
              text: '文件管理架构图',
              ref: 'core8_2',
            },
          ],
        },
        {
          label: 'BI引擎',
          children: [
            {
              title: '1.BI引擎',
              subtitle:
                'JEPaaS的BI引擎可快速搭建面向全员的数据分析及BI平台，实现业务数据的可视化分析，充分了解并利用数据，辅助决策、提升业务。',
              img: core9_1,
              text: '图形报表案例',
              ref: 'core9_1',
            },
            {
              title: '1.BI引擎',
              subtitle:
                'JEPaaS的BI引擎可快速搭建面向全员的数据分析及BI平台，实现业务数据的可视化分析，充分了解并利用数据，辅助决策、提升业务。',
              img: core9_2,
              text: '图表分析',
              ref: 'core9_2',
            },
            {
              title: '1.BI引擎',
              subtitle:
                'JEPaaS的BI引擎可快速搭建面向全员的数据分析及BI平台，实现业务数据的可视化分析，充分了解并利用数据，辅助决策、提升业务。',
              img: core9_3,
              text: '交叉报表',
              ref: 'core9_3',
            },
          ],
        },
        {
          label: '数据导入导出',
          children: [
            {
              title: '1.数据导入导出',
              subtitle:
                'JEPaaS支持Excel的数据导入功能，提供规划模版工具，可解析各种复杂的excel数据内容排版，将用户的excel数据直接导入到系统中。其中，导出主要提供列表的导出、打印格式的导入、也可以自己规划模版来将数据导出。',
              img: core10_1,
              text: 'Excel的数据快速导入导出',
              ref: 'core10_1',
            },
          ],
        },
        {
          label: 'Web打印模块',
          children: [
            {
              title: '1.Web打印模块',
              subtitle:
                'JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。',
              img: core11_1,
              text: '表格打印配置',
              ref: 'core11_1',
            },
            {
              title: '1.Web打印模块',
              subtitle:
                'JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。',
              img: core11_2,
              text: '表单打印配置',
              ref: 'core11_2',
            },
            {
              title: '1.Web打印模块',
              subtitle:
                'JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。',
              img: core11_3,
              text: '表格打印案例',
              ref: 'core11_3',
            },
            {
              title: '1.Web打印模块',
              subtitle:
                'JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。',
              img: core11_4,
              text: '表单打印案例',
              ref: 'core11_4',
            },
          ],
        },
        {
          label: '多数据源处理',
          children: [
            {
              title: '1.多数据源处理',
              subtitle:
                'JEPaaS提供可维护指定数据库，加载指定的数据源，引用集成外部系统、外部数据源来完成业务操作，如：在本系统中想查看考勤系统的报表，可直接引用数据源，利用自己jepass的报表工具来完成展示。',
              img: core12_1,
              text: '多数据源稳定高效处理',
              ref: 'core12_1',
            },
          ],
        },
      ],
      swipercurrent: {},
    };
  },
  mounted() {
    this.saascurrent = this.saaslist[0];
    this.$nextTick(() => {
      const i = this.$route.query.id ? this.$route.query.id - 1 : 0;
      this.$set(this, 'swipercurrent', this.swiperlist[i]);
      if (this.$route.query.id || this.$route.query.id == 0) {
        document.documentElement.scrollTop = document.querySelectorAll('.swiper_wrp')[0].offsetTop - 150;
        this.$refs.header.topActive = 1;
      }
    });
  },
  methods: {
    handleSaasListTab(item) {
      this.saascurrent = item;
    },
    handleSwiperChange(item) {
      // 停止上一个轮播的自动播放
      // const index = this.swiperlist.indexOf(this.swipercurrent);
      // this.$refs[`swiper_${index}`][0].swiper.autoplay.pause();
      this.swipercurrent = item;
      // 开始新轮播的自动播放
      // this.$refs[`swiper_${i}`][0].swiper.autoplay.run();
      // this.$refs[`swiper_${i}`][0].swiper.disableOnInteraction = false;
    },
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
  },
};
</script>

<style lang="less" scoped>
.product_jepaas_content {
  .box_top {
    width: 100%;
    height: 600px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    .top_tlite {
      padding: 160px 0 0 195px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      > p {
        padding-left: 180px;
      }
      > .title {
        font-size: 60px;
        margin: 0;
        text-align: left;
      }
      > .subtitle {
        font-size: 30px;
        margin: 20px 0 70px;
      }
      > .btn {
        margin-left: 180px;
        text-align: center;
        box-sizing: border-box;
        width: 200px;
        height: 60px;
        line-height: 60px;
        border: 2px solid rgba(255, 255, 255, 1);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 6px;
        cursor: pointer;
      }
    }
    .top_img {
      display: flex;
      align-items: flex-end;
      img {
        width: 963px;
        height: 447px;
      }
    }
  }
  .page_content {
    text-align: center;
    .section {
      // width: 1200px;
      margin: 0 auto;
      padding: 100px 0;
      &.imgth {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 239, 235, 1) 100%
        );
      }
      &.swiper {
        background: #ecf3f9;
      }
      .cpu_img {
        width: 800px;
      }
      &.spe {
        .title {
          margin-top: 0;
        }
        .subtitle {
          margin-bottom: 10px;
        }
      }
      .title {
        font-size: 40px;
        color: #000000;
        text-align: center;
        font-weight: bold;
      }
      .tablist {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          width: 290px;
          height: 60px;
          background: #f0f0f0;
          color: #303336;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover,
          &.active {
            font-weight: bold;
            background: #ff3041;
            color: #ffffff;
          }
        }
      }
      .subtitle {
        font-size: 16px;
        color: #303336;
        text-align: left;
        margin: 60px auto 50px;
        width: 1200px;
        &.short {
          width: 900px;
        }
      }
      .doubleimg {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        > div {
          width: 508px;
          > img {
            width: 508px;
            height: 467px;
          }
          > p {
            font-size: 18px;
            margin-top: 40px;
            text-align: center;
            font-weight: bold;
          }
        }
      }
    }
    .swiper_wrp {
      width: 1200px;
      display: flex;
      margin: 0 auto;
      .left {
        width: 200px;
        > div {
          width: 200px;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #ffffff;
          background: #f4f4f4;
          text-align: center;
          font-size: 16px;
          position: relative;
          border-right: 1px solid #dcdcdc;
          cursor: pointer;
          &.active,
          &:hover {
            background: #ffffff;
            border-right: 0;
            &:before {
              content: "";
              width: 5px;
              background: #ff3041;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
      .right {
        background: #ffffff;
        width: 1000px;
        .swiper-items {
          padding: 15px 30px 0;
          box-sizing: border-box;
          background: #ffffff;
          > p,
          > div {
            width: 840px;
            margin: 0 auto;
          }
          .title {
            color: #333333;
            font-size: 24px;
          }
          .subtitle {
            margin: 20px auto;
            color: #666666;
            font-size: 16px;
            height: 70px;
          }
          .img_box {
            width: 840px;
            height: 525px;
            overflow: hidden;
            background-size: cover;
          }
          .text {
            margin: 10px auto 0;
            font-size: 16px;
            color: #333333;
          }
        }
        .swiper-container {
          width: 100%;
          height: 732px;
        }
      }
      .swiper-button-prev {
        background: rgba(0, 0, 0, 0.1);
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          border: 1px solid #ffffff;
          border-right-color: transparent;
          border-bottom-color: transparent;
          // translate:
          display: block;
          position: absolute;
          top: 50%;
          left: 75%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
      .swiper-button-next {
        background: rgba(0, 0, 0, 0.1);
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          border: 1px solid #ffffff;
          border-left-color: transparent;
          border-bottom-color: transparent;
          // translate:
          display: block;
          position: absolute;
          top: 50%;
          left: 25%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }
  .fullWidth {
    width: 100%;
  }
  .fullHeight {
    height: 100%;
  }
}
</style>
<style lang="less">
.product_jepaas_content {
  .el-carousel__container {
    height: 732px;
  }
  .el-carousel__indicators.el-carousel__indicators--horizontal.el-carousel__indicators--outside {
    display: none;
  }
}
</style>
